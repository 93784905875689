import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useBasket } from '~/features/basket';
import { ProductEventPayload } from '~/features/productList/PLPModule.definitions';
import { EventIdentifier } from '~/shared/hooks/useEvents';
import { useErrorHandler } from '~/shared/utils/errorBoundary/hooks/useErrorHandler';

export const useProductHandler = () => {
    const router = useRouter();
    const { addItemToBasket, basketId, toggleMiniBasket } = useBasket();
    const { trackAndDisplayError: trackError } = useErrorHandler();

    const getProductHandler = useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        async (identifier: EventIdentifier, data: ProductEventPayload) => {
            //const typeLowered = data.type.toLowerCase();
            try {
                switch (data.type) {
                    case 'addtocart': {
                        return (
                            addItemToBasket(data.productEventData, data.payload, data.quantity),
                            toggleMiniBasket(true)
                        );
                    }
                    case 'findinstore': {
                        return router.push(data.payload.url);
                    }
                    case 'navigateto': {
                        return router.push(data.payload.url);
                    }

                    default:
                        return () => {
                            throw new Error('Missing handler with data');
                        };
                }
            } catch (error) {
                trackError(error);
            }
        },
        [basketId]
    );

    return getProductHandler;
};
